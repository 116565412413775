import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { md } from 'utils/breakpoints';
import { SEO_DOMAIN } from 'utils/constants/seo.js';

const SeoHome = ({ serverData }) => {
  return (
    <>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.home1?.data?.userInfo?.frequentlyPurchasedList?.rows?.map(
            (product, idx) => {
              return (
                <div
                  typeof="schema:Product"
                  key={`FrequentlyPurchasedList-${idx}`}
                >
                  <div property="schema:name" content={product?.itemName}></div>
                  {product?.images?.map((url) => {
                    return <div rel="schema:image" resource={url}></div>;
                  })}
                  <div rel="schema:image" resource={product?.image}></div>;
                  <div
                    property="schema:description"
                    content={product?.itemSpec}
                  ></div>
                  <div
                    rel="schema:url"
                    resource={`${SEO_DOMAIN}/product?pid=${product?.productNumber}`}
                  ></div>
                  <div rel="schema:brand">
                    <div typeof="schema:Brand">
                      <div
                        property="schema:name"
                        content={product?.brand}
                      ></div>
                    </div>
                  </div>
                  <div rel="schema:offers">
                    <div typeof="schema:Offer">
                      <div
                        property="schema:price"
                        content={
                          product?.salePrice !== product?.price
                            ? product?.salePrice
                            : product?.price
                        }
                      ></div>
                      <div
                        property="schema:availability"
                        content="https://schema.org/InStock"
                      ></div>
                      <div property="schema:priceCurrency" content="TWD"></div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.home1?.data?.userInfo?.recommendProductList?.map(
            (product, idx) => {
              return (
                <div
                  typeof="schema:Product"
                  key={`RecommendProductList-${idx}`}
                >
                  <div property="schema:name" content={product?.itemName}></div>
                  {product?.images?.map((url) => {
                    return <div rel="schema:image" resource={url}></div>;
                  })}
                  <div rel="schema:image" resource={product?.image}></div>;
                  <div
                    property="schema:description"
                    content={product?.itemSpec}
                  ></div>
                  <div
                    rel="schema:url"
                    resource={`${SEO_DOMAIN}/product?pid=${product?.productNumber}`}
                  ></div>
                  <div rel="schema:brand">
                    <div typeof="schema:Brand">
                      <div
                        property="schema:name"
                        content={product?.brand}
                      ></div>
                    </div>
                  </div>
                  <div rel="schema:offers">
                    <div typeof="schema:Offer">
                      <div
                        property="schema:price"
                        content={
                          product?.salePrice !== product?.price
                            ? product?.salePrice
                            : product?.price
                        }
                      ></div>
                      <div
                        property="schema:availability"
                        content="https://schema.org/InStock"
                      ></div>
                      <div property="schema:priceCurrency" content="TWD"></div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.home2?.data?.popCategoriesList?.rows?.forEach(
            (cate, idx) => {
              cate?.productList?.map((product, idx) => {
                return (
                  <div typeof="schema:Product" key={`PopCategoriesList-${idx}`}>
                    <div
                      property="schema:name"
                      content={product?.itemName}
                    ></div>
                    <div rel="schema:image" resource={product?.images}></div>;
                    <div
                      property="schema:description"
                      content={product?.itemSpec}
                    ></div>
                    <div
                      rel="schema:url"
                      resource={`${SEO_DOMAIN}/product?pid=${product?.productNumber}`}
                    ></div>
                    <div rel="schema:brand">
                      <div typeof="schema:Brand">
                        <div
                          property="schema:name"
                          content={product?.brand}
                        ></div>
                      </div>
                    </div>
                    <div rel="schema:offers">
                      <div typeof="schema:Offer">
                        <div
                          property="schema:price"
                          content={
                            product?.salePrice !== product?.price
                              ? product?.salePrice
                              : product?.price
                          }
                        ></div>
                        <div
                          property="schema:availability"
                          content="https://schema.org/InStock"
                        ></div>
                        <div
                          property="schema:priceCurrency"
                          content="TWD"
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              });
            }
          )}
        </div>
      </div>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.home1?.data?.bannerInfo?.rows?.map((banner, idx) => {
            return (
              <div typeof="schema:ListItem" key={`BannerInfo-${idx}`}>
                <div property="schema:position" content={idx + 1}></div>
                <div property="schema:name" content={banner?.name}></div>
                <div rel="schema:url" resource={banner?.url}></div>
              </div>
            );
          })}
        </div>
      </div>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.home1?.data?.couponPomotionInfo?.rows?.map(
            (pomotion, idx) => {
              return (
                <div typeof="schema:ListItem" key={`CouponPomotionInfo-${idx}`}>
                  <div rel="schema:image" resource={pomotion?.image}></div>
                  <div property="schema:position" content={idx + 1}></div>
                  <div
                    property="schema:name"
                    content={pomotion?.imageDesc}
                  ></div>
                  <div rel="schema:url" resource={pomotion?.url}></div>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.cateMenu?.data?.rows?.forEach((cate) => {
            cate?.sub?.forEach((subItem, idx) => {
              return (
                <div typeof="schema:ListItem" key={`cateMenu-${idx}`}>
                  <div property="schema:position" content={idx + 1}></div>
                  <div property="schema:name" content={subItem?.name}></div>
                  <div
                    rel="schema:url"
                    resource={`${SEO_DOMAIN}/category?cid=${subItem?.id}`}
                  ></div>
                </div>
              );
            });
          })}
        </div>
      </div>
    </>
  );
};

export default SeoHome;
