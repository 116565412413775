import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import { navigate, Link } from 'gatsby';
import store, { actions, getters } from 'redux/store';
import {
  SEO_API_HOMEPAGE_ONE_NOTOKEN,
  SEO_API_HOMEPAGE_TWO_NOTOKEN,
  SEO_API_CATEGORIES_NOTOKEN,
} from 'utils/constants/seo.js';
import { LOGOUT_TYPE } from 'utils/constants/logoutType';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import MetaHome from 'components/Seo/Meta/MetaHome';
import LdHome from 'components/Seo/JsonLd/LdHome';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Footer from 'components/UI/Footer';
import CcAuth from 'components/CcAuth';
import CategoryMenu from 'components/Homepage/CategoryMenu';
import SwiperListSection from 'components/Homepage/SwiperListSection';
import OrderNotification from 'components/Homepage/OrderNotification';
import SeoHome from 'components/Seo/SeoHome';
import { Swiper, SwiperSlide } from 'components/UI/Swiper';

import { md } from 'utils/breakpoints';
import { useAPI, useAPIMethod } from 'utils/api';
import { isAuthenticated, clearAuth } from 'utils/_auth.js';
import { getDispatchData, clearDispatchData } from 'utils/_dispatch.js';
import { setSelectBanner, setSelectProductList } from 'utils/_gtag';
import isbot from 'isbot';
import { urlFilter } from 'utils/urlHandler';
import { logBQ } from 'utils/_omsLog';

const mixinBanner = css`
  height: 203px;
  border-radius: 15px;

  @media (max-width: ${md}) {
    height: 161px;
  }
`;

const mixinCategoryItem = css`
  border-radius: 8px;
  height: 88px;
`;

const Container = styled.div`
  width: 1080px;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  @media (max-width: ${md}) {
    width: 100%;
  }

  .skeleton-main-banner-wrapper {
    display: flex;
    gap: 0 15px;
    margin-bottom: 24px;

    @media (max-width: ${md}) {
      gap: 0 8px;
      padding-left: 16px;
    }

    .skeleton-main-banner {
      ${mixinBanner};
    }
  }

  .skeleton-category-list-wrapper {
    display: flex;
    gap: 0 5px;

    @media (max-width: ${md}) {
      display: none;
    }

    .skeleton-category-list {
      ${mixinCategoryItem};
    }
  }
`;

const StyledHeader = styled(Header)``;

const SwiperCategory = styled(Swiper)`
  margin: 19px 0 16px 0;

  &.swiper {
    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const CategoryItem = styled(Link)`
  ${mixinCategoryItem};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
`;

const CategoryItemImage = styled.img`
  width: 64px;
  height: 64px;
`;

const CategoryItemName = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #3b3516;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DeliveryTimeText = styled.div`
  color: #3b3516;
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    margin-bottom: 12px;
    padding: 0 16px;
  }

  > span {
    color: #5fd2da;
  }
`;

const SwiperBanner = styled(Swiper)`
  margin-bottom: 24px;

  @media (max-width: ${md}) {
    &.swiper {
      padding-left: 16px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
`;

const BannerFrame = styled.div`
  ${mixinBanner};
  display: contents;
`;

const Banner = styled.img`
  ${mixinBanner};
  display: block;
  background-image: url(${(props) => props.src});
  background-size: cover;
`;

const MainSection = styled.div`
  display: flex;
  gap: 0 16px;

  @media (max-width: ${md}) {
    padding-left: 16px;
  }
`;

const Content = styled.div``;

const MobileBannerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0 8px;
  overflow-x: scroll;
  padding: 0 16px;
  margin-bottom: 24px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${Banner} {
    flex: 0 0 235px;
  }
`;

const isGoogleBot = (res) => {
  return /Googlebot/.test(res);
};

const SLIDES_PER_VIEW_MAIN_BANNER = 3.5;

const Homepage = ({ serverData }) => {
  console.log('serverData', serverData);
  const api = useAPI();
  const dispatchData = getDispatchData();

  /* const ccAuthIsReady = useSelector((state) => getters.getInfoIsReady(state)); */
  const homePageOne = useSelector((state) => getters.getHomePageOne(state));
  const homePageTwo = useSelector((state) => getters.getHomePageTwo(state));
  const categoryList = useSelector((state) => getters.getCategoryList(state));
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const shippingModel = useSelector((state) => getters.getShippingModel(state));
  const latLng = useSelector((state) => getters.getLatLng(state));
  const shippingInfo = useSelector((state) => getters.getShippingInfo(state));

  const [headerHeight, setHeaderHeight] = useState(0);
  const [rangeTime, setRangeTime] = useState(20);
  const [isLogin, setIsLogin] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const [reFreshCategoryMenu, setReFreshCategoryMenu] = useState(false);
  const [isShippingInfoIsReady, setIsShippingInfoIsReady] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const startTime = useRef(Date.now());
  const elapsedTime = useRef(0);

  const updateTimer = () => {
    elapsedTime.current = Date.now() - startTime.current;
  };

  // 當userInfo與homePageOne有值時，執行logBQ
  useEffect(() => {
    if (Object.keys(userInfo).length > 0 && homePageOne) {
      logBQ({
        device: 'pcweb',
        account: userInfo.mobile ?? 'none',
        ms: elapsedTime.current,
        api_name: 'Api-homepage1',
        api_data_name: 'all',
        log: JSON.stringify(homePageOne),
      });
    }
  }, [userInfo, homePageOne]);

  const homePageData = (latitude, longitude, storeId) => {
    const params = {
      current_latitude: latitude,
      current_longitude: longitude,
      storeId: storeId ?? dispatchData?.storeId ?? '',
      customer_no: userInfo.customerNo,
      address: dispatchData?.receiverAddress ?? '',
    };

    // 每隔1毫秒更新一次
    const timerInterval = setInterval(updateTimer, 1);
    api
      .getHomePageOne(params)
      .then((res) => {
        //移除計時器
        clearInterval(timerInterval);

        store.dispatch(actions.setHomePageOne(res));
        /* setRangeTime(res.estimated_fastest_arrival_time); */

        //把專屬推薦存到localstorage 否則會其他頁造成refresh資料會不見
        const frequentlyPurchasedList = JSON.stringify(
          res.userInfo.frequentlyPurchasedList.rows
        );
        localStorage.setItem(
          'frequentlyPurchasedList',
          frequentlyPurchasedList
        );
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getHomePageTwo(params)
      .then((res) => {
        store.dispatch(actions.setHomePageTwo(res));
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getRangeTime()
      .then((res) => {
        setRangeTime(res.estimated_fastest_arrival_time);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const homePageDataNoToken = (latitude, longitude) => {
    const params = {
      current_latitude: latitude,
      current_longitude: longitude,
      storeId: dispatchData?.storeId ?? '',
    };
    api
      .getHomePageOneNoToken(params)
      .then((res) => {
        store.dispatch(actions.setHomePageOne(res));
        setRangeTime(res.estimated_fastest_arrival_time);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getHomePageTwoNoToken(params)
      .then((res) => {
        store.dispatch(actions.setHomePageTwo(res));
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .getRangeTime()
      .then((res) => {
        setRangeTime(res.estimated_fastest_arrival_time);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkLogin = (latitude, longitude) => {
    if (isAuthenticated()) {
      homePageData(latitude, longitude);
    } else {
      homePageDataNoToken(latitude, longitude);
    }
  };

  const getOrderNotification = () => {
    setIsShippingInfoIsReady(false);
    api
      .getOrderNotification()
      .then((res) => {
        store.dispatch(actions.setShippingInfo(res.shippingInfo.rows));
        setIsShippingInfoIsReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCouponList = () => {
    api
      .getCouponList()
      .then((res) => {
        setCouponList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem('doLogout') !== null) {
      sessionStorage.removeItem('isSSO');
      localStorage.removeItem('doLogout');
      clearAuth();
      store.dispatch(actions.setUserInfo({}));
      store.dispatch(actions.setAuthIsReady(false));
      store.dispatch(actions.setInfo({}));
      store.dispatch(actions.setCartItemCount(0));
      store.dispatch(actions.setCartProductCountList([]));
      if (localStorage.getItem('doLogout') === LOGOUT_TYPE.LogoutClearAll) {
        clearDispatchData();
      }
    }
    getCouponList();
    setDomLoaded(true);
    store.dispatch(actions.setHomePageOne(undefined));
    store.dispatch(actions.setHomePageTwo(undefined));
  }, []);

  useEffect(() => {
    if (Object.keys(latLng).length > 0) {
      setIsLogin(isAuthenticated());
      checkLogin(latLng.latitude, latLng.longitude);
    }
  }, [latLng]);

  useEffect(() => {
    if (isAuthenticated() && ccAuthIsReady) {
      getOrderNotification();
    }
  }, [ccAuthIsReady]);

  return (
    <>
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      <MetaHome serverData={serverData}></MetaHome>
      <LdHome serverData={serverData}></LdHome>
      {isbot(serverData?.useragent) ? (
        <SeoHome serverData={serverData}></SeoHome>
      ) : domLoaded && ccAuthIsReady ? (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            {isLogin && shippingInfo.length > 0 && isShippingInfoIsReady && (
              <OrderNotification shippingOrderList={shippingInfo} />
            )}
            <StyledHeader
              onRefresh={(storeId) => {
                store.dispatch(actions.setHomePageOne(undefined));
                store.dispatch(actions.setHomePageTwo(undefined));
                setReFreshCategoryMenu(true);
                homePageData(latLng.latitude, latLng.longitude, storeId);
              }}
            />
          </HeaderWrapper>
          <Content>
            {categoryList ? (
              <SwiperCategory
                slidesPerView={10}
                slidesPerGroup={10}
                spaceBetween={5}
                modules={[Navigation]}
                navigation
              >
                {categoryList?.map((item, idx) => {
                  const sub = item.sub[0];
                  return (
                    <SwiperSlide
                      key={idx}
                      onClick={() => {
                        // GTAG: 4. 商品分類
                        setSelectProductList(item);
                      }}
                    >
                      <CategoryItem to={`/category?cid=${sub.id}`}>
                        <CategoryItemImage src={item.image} />
                        <CategoryItemName>{item.name}</CategoryItemName>
                      </CategoryItem>
                    </SwiperSlide>
                  );
                })}
              </SwiperCategory>
            ) : (
              <Skeleton
                containerClassName="skeleton-category-list-wrapper"
                className="skeleton-category-list"
                count={10}
                inline
              />
            )}
            {shippingModel === '1' && (
              <DeliveryTimeText>
                最快 <span>{`${rangeTime}-${rangeTime + 15}`}</span> 分鐘送達
              </DeliveryTimeText>
            )}

            {/* ------- 大 Banner ------- */}
            {homePageOne && !isMobile && (
              <SwiperBanner
                slidesPerView={SLIDES_PER_VIEW_MAIN_BANNER}
                slidesPerGroup={Math.floor(SLIDES_PER_VIEW_MAIN_BANNER)}
                spaceBetween={15}
                modules={[Navigation]}
                navigation
              >
                {homePageOne?.bannerInfo.rows.map((item, idx) => {
                  return (
                    <SwiperSlide
                      onClick={() => {
                        // GTAG: 點擊廣告輪播Banner(上)
                        setSelectBanner(idx, item);
                      }}
                    >
                      <Banner
                        onClick={() => {
                          item.web_redirect_url !== '' &&
                          item.redirectType === '2'
                            ? typeof window !== 'undefined'
                              ? window.open(item.web_redirect_url)
                              : ''
                            : navigate(urlFilter(item.web_redirect_url), {
                                replace: false,
                              });
                        }}
                        src={item.image}
                      />
                    </SwiperSlide>
                  );
                })}
              </SwiperBanner>
            )}
            {homePageOne && isMobile && (
              <MobileBannerWrapper>
                {homePageOne?.bannerInfo.rows.map((item, idx) => {
                  return (
                    <BannerFrame
                      onClick={() => {
                        // GTAG: 點擊廣告輪播Banner(上)
                        setSelectBanner(idx, item);
                      }}
                    >
                      <Banner
                        onClick={() => {
                          item.web_redirect_url !== '' &&
                          item.redirectType === '2'
                            ? typeof window !== 'undefined'
                              ? window.open(item.web_redirect_url)
                              : ''
                            : navigate(urlFilter(item.web_redirect_url), {
                                replace: false,
                              });
                        }}
                        src={item.image}
                      />
                    </BannerFrame>
                  );
                })}
              </MobileBannerWrapper>
            )}
            {!homePageOne && (
              <Skeleton
                containerClassName="skeleton-main-banner-wrapper"
                className="skeleton-main-banner"
                count={isMobile ? 1.5 : 3.5}
                inline
              />
            )}
            {/* ------- 大 Banner ------- */}

            <MainSection>
              <CategoryMenu
                onRefresh={reFreshCategoryMenu}
                onFinish={() => setReFreshCategoryMenu(false)}
              />
              <SwiperListSection
                homePageOne={homePageOne}
                homePageTwo={homePageTwo}
                couponTicketList={couponList}
              />
            </MainSection>
          </Content>
          <Footer />
        </Container>
      ) : undefined}
    </>
  );
};

export default Homepage;

export async function getServerData(context) {
  try {
    const headers = context.headers;

    console.log('context', context);
    /* if (/Googlebot/.test(headers.get('user-agent')) === false) {
      const mergedData = {
        useragent: headers.get('user-agent'),
      };
      return {
        status: 200,
        headers: {},
        props: mergedData,
      };
    } */
    const [res1, res2, res3] = await Promise.all([
      fetch(`${SEO_API_HOMEPAGE_ONE_NOTOKEN}?storeId=02H`),
      fetch(`${SEO_API_HOMEPAGE_TWO_NOTOKEN}?storeId=02H`),
      fetch(`${SEO_API_CATEGORIES_NOTOKEN}?storeId=02H`),
    ]);

    if (!res1.ok || !res2.ok || !res3.ok) {
      throw new Error(`Response failed`);
    }

    const home1 = await res1.json();
    const home2 = await res2.json();
    const cateMenu = await res3.json();

    const mergedData = {
      home1,
      home2,
      cateMenu,
      useragent: headers.get('user-agent'),
      host: headers.get('host'),
    };

    return {
      props: await mergedData,
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
