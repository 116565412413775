import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { set } from 'lodash';

import ModalDetail from 'pages/my-coupon/ModalDetail';
import Auth from 'components/Auth';

import { useAPI } from 'utils/api';
import { md } from 'utils/breakpoints';
import { isAuthenticated } from 'utils/_auth.js';
import { COUPON_STATUS } from 'utils/constants/couponStatus';

const Container = styled.div`
  width: 100%;
  height: 114px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fffde9;
  display: flex;
  overflow: hidden;

  ${(props) =>
    props.isDone &&
    css`
      ${StatusWrapper} {
        background-color: #5fd2da;
      }

      ${DescriptionBtn} {
        background-color: #6fdee5;
      }
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const InfoWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #e75555;
  line-height: 21px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 0 5px;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #666;

  > div {
    line-height: 16px;
  }
`;

const StatusWrapper = styled.div`
  flex: 0 0 80px;
  border-left: 3px dashed #fffde9;
  background-color: #ff7a7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Status = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #f0f0f0;
`;

const DescriptionBtn = styled.button`
  width: 64px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: none;
  background-color: #ff8e8e;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 0;
  cursor: pointer;
`;

const Coupon = (props) => {
  const { data, status = COUPON_STATUS.NONE } = props;
  /* const isDone = !!Math.round(Math.random()); */
  const api = useAPI();

  const [doLogin, setDoLogin] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [isReceive, setIsReceive] = useState(false);
  const [isIssuedAll, setIsIssuedAll] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  const receiveCoupon = () => {
    if (isReceive) return;
    api
      .receiveCoupon({ couponId: data?.id })
      .then((res) => {
        if (!res) {
          setIsReceive(false);
          return;
        }
        alert('領取成功');
        setIsReceive(true);
        console.log('receiveCoupon', res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setTicketStatus = (ticketStatus) => {
    switch (ticketStatus) {
      case COUPON_STATUS.CAN_USE:
        setIsDisable(false);
        break;
      case COUPON_STATUS.CAN_NOT_USE:
        setIsDisable(true);
        break;
      case COUPON_STATUS.NOT_ACTIVE:
        setIsDisable(true);
        break;
    }
  };

  useEffect(() => {
    if (data?.isOwner) {
      setIsReceive(true);
      setIsDisable(false);
    } else {
      setIsReceive(false);
      setIsDisable(false);
    }

    if (status !== COUPON_STATUS.NONE) {
      setIsReceive(true);
      setTicketStatus(status);
    }

    if (data?.isIssuedAll && !data?.isOwner) {
      setIsIssuedAll(true);
      setIsDisable(true);
    }
  }, []);

  return (
    <>
      <Container isDone={isReceive} isDisabled={isDisable}>
        {doLogin && <Auth />}
        <InfoWrapper>
          <Title>
            {data?.couponName}
            <span>折價券</span>
          </Title>

          <Subtitle>{data?.couponTitle}</Subtitle>

          <Description>
            <div>{data?.dateDesc}</div>
          </Description>
        </InfoWrapper>

        <StatusWrapper
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            isAuthenticated() ? receiveCoupon() : setDoLogin(true);
          }}
        >
          {isIssuedAll ? (
            <Status>已領完</Status>
          ) : (
            <Status>{isReceive ? '已領' : '領券'}</Status>
          )}
        </StatusWrapper>
      </Container>

      <ModalDetail
        text={data?.couponDescription}
        isOpen={showModalDetail}
        onOk={closeModalDetail}
        onCancel={closeModalDetail}
      />
    </>
  );
};

export default Coupon;
