import React, { useEffect, useState } from 'react';
import { getters } from 'redux/store';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { Swiper, SwiperSlide } from 'components/UI/Swiper';
import CardProduct from 'components/UI/Card/CardProduct';

import { md } from 'utils/breakpoints';
import { isAuthenticated } from 'utils/_auth.js';
import { getDispatchData } from 'utils/_dispatch.js';
import { setHomePromoCatMore, setSelectItem } from 'utils/_gtag.js';

import 'swiper/css/grid';

const mixinCardProduct = css`
  height: 291px;

  @media (max-width: ${md}) {
    height: 207px;
  }
`;

const StyledCardProduct = styled(CardProduct)`
  ${mixinCardProduct};

  @media (max-width: ${md}) {
    width: 100%;
    padding: 18px 8px 8px;
  }

  .img-wrapper {
    @media (max-width: ${md}) {
      margin: 0 auto 16px;
    }
  }
`;

const SwiperBlockTitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  @media (max-width: ${md}) {
    margin-bottom: 8px;
  }
`;

const SwiperBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 18px;
  }
`;

const SwiperBlockCheckMore = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1430b3;
  margin-left: auto;

  @media (max-width: ${md}) {
    font-size: 14px;
    margin-right: 16px;
  }
`;

const SLIDES_PER_VIEW = isMobile ? 2.5 : 4.1;

const SwiperBlockContent = (props) => {
  const { item, idx } = props;

  const dispatchData = getDispatchData();
  const storeId =
    dispatchData?.storeId ?? info.nearbyShopInfo?.recommandStoreId;

  const cartProductList = useSelector((state) =>
    getters.getCartProductCountList(state)
  );
  const [productList, setProductList] = useState();

  const handleChangeCount = (count, productId) => {
    const updatedProduct = productList.map((product) => {
      if (productId === product.productNumber) {
        return {
          ...product,
          count: count,
        };
      }
      return product;
    });
    setProductList(updatedProduct);
  };

  const checkProductQty = () => {
    const newProds = [];
    productList?.map((p) => {
      let result = cartProductList?.find((infoProd) => {
        return p.productNumber === infoProd.productNo;
      });
      const asProd = { ...p, count: p.count ?? 0 };

      result !== undefined ? (asProd.count = result.count) : (asProd.count = 0);

      newProds.push(asProd);
    });
    setProductList(newProds);
  };

  useEffect(() => {
    setProductList(item.productList);
  }, []);

  useEffect(() => {
    if (cartProductList.length > 0 && productList) {
      checkProductQty();
    }
  }, [item.productList, cartProductList]);

  return (
    <>
      <SwiperBlockTitleBar>
        <SwiperBlockTitle>{item.name}</SwiperBlockTitle>
        {item.name !== '常購商品' && item.name !== '其他人也逛過' && (
          <SwiperBlockCheckMore
            onClick={() => {
              // GTAG: 點選"主題商品分類"區的"看更多"
              setHomePromoCatMore(idx, item);
            }}
          >
            <Link to={`/category?cid=${item.id}`}>看更多</Link>
          </SwiperBlockCheckMore>
        )}
      </SwiperBlockTitleBar>
      <Swiper
        modules={[Navigation]}
        navigation
        slidesPerView={SLIDES_PER_VIEW}
        slidesPerGroup={Math.floor(SLIDES_PER_VIEW)}
        spaceBetween={isMobile ? 8 : 4}
      >
        {productList?.map((product, idx) => {
          const itemData = {
            id: product.productNumber,
            storeId: storeId,
            img: product.images ?? product.image,
            price: product.salesPrice,
            originalPrice: product.price,
            name: product.itemName,
            count: product.count ?? 0,
          };
          return (
            <SwiperSlide key={idx}>
              <StyledCardProduct
                goToPage={`/product?pid=${itemData.id}`}
                item={itemData}
                productData={product}
                boardInfo={{
                  id: item.id,
                  name: item.name,
                }}
                onGo2Detail={(id) => {
                  // GTAG: 點選"主題商品類別"區商品
                  setSelectItem(
                    {
                      listId: item.id,
                      listName: item.name,
                    },
                    product
                  );
                }}
                onChangeCount={(count, productId) => {
                  handleChangeCount(count, productId);
                }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default SwiperBlockContent;
