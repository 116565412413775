import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHome = ({ serverData }) => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="SPfm0HwrG6Zg9RPfJM0oGWe4oL0QZpBZ3qS8UDe0buo"
        />
        <meta property="og:type" content="website" defer={false}></meta>
        <title>家速配，生鮮、雜貨、日用品，最快1小時送達！</title>
        <meta
          property="description"
          content={`家速配是家樂福快速配送服務，生鮮蔬果、麵包牛奶、零食飲料、衛生紙、日用品通通都訂得到！新客輸入折扣碼「NEW」享99元免運！三大特色→ 一、可累積也可折抵家樂福會員紅利點數，1元贈1點終生有效！二、預約下單可指定時間，宅配、到店取貨都方便！三、標示24h門市不打烊，半夜也能送到家。連金門也有服務喔~下載家速配APP更方便！`}
        ></meta>
        <meta
          property="og:title"
          content={`家速配，生鮮、雜貨、日用品，最快1小時送達！`}
        />
        <meta
          property="og:description"
          content={`家速配是家樂福快速配送服務，生鮮蔬果、麵包牛奶、零食飲料、衛生紙、日用品通通都訂得到！新客輸入折扣碼「NEW」享99元免運！三大特色→ 一、可累積也可折抵家樂福會員紅利點數，1元贈1點終生有效！二、預約下單可指定時間，宅配、到店取貨都方便！三、標示24h門市不打烊，半夜也能送到家。連金門也有服務喔~下載家速配APP更方便！`}
        ></meta>
        <meta property="og:url" content={`https://${serverData?.host}`} />
        {/* logo圖檔放在static裏面 部署的時候會搬到public */}
        <meta
          property="og:image"
          content={`https://${serverData?.host}/useimages/logo.png`}
        />
      </Helmet>
    </>
  );
};

export default MetaHome;
