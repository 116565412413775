import React, { useEffect, useState } from 'react';
import { getters } from 'redux/store';
import { Link, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import { Grid, Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'components/UI/Swiper';
import Coupon from 'components/UI/Card/Coupon';
import SwiperBlockContent from './SwiperBlockContent';

import { md } from 'utils/breakpoints';

import { setSelectPromotion } from 'utils/_gtag.js';

import 'swiper/css/grid';
import { urlFilter } from 'utils/urlHandler';

const mixinCategory = css`
  height: 94px;
  border-radius: 10px;
`;

const mixinBanner = css`
  height: 168px;
  border-radius: 15px;

  @media (max-width: ${md}) {
    height: 117px;
  }
`;

const mixinCardProduct = css`
  height: 291px;

  @media (max-width: ${md}) {
    height: 207px;
  }
`;

const Container = styled.div`
  flex: 1;
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;

  @media (max-width: ${md}) {
    gap: 21px 0;
  }

  .skeleton-category-wrapper {
    display: none;

    @media (max-width: ${md}) {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .skeleton-category {
      @media (max-width: ${md}) {
        ${mixinCategory};
        flex: 0 0 calc((100% - 16px) / 3);
      }
    }
  }

  .skeleton-banner-wrapper {
    display: flex;
    gap: 0 16px;

    .skeleton-banner {
      ${mixinBanner};
    }
  }

  .skeleton-pop-category-wrapper {
    display: flex;
    gap: 0 4px;

    @media (max-width: ${md}) {
      gap: 0 8px;
    }

    .skeleton-pop-category {
      ${mixinCardProduct};
    }
  }
`;

const SwiperBlock = styled.div`
  &.category {
    display: none;

    @media (max-width: ${md}) {
      display: block;
    }

    .swiper-slide {
      height: auto;
    }
  }
`;

const SwiperBlockTitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  @media (max-width: ${md}) {
    margin-bottom: 8px;
  }
`;

const SwiperBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 18px;
  }
`;

const SwiperBlockCheckMore = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  color: #1430b3;
  margin-left: auto;

  @media (max-width: ${md}) {
    font-size: 14px;
    margin-right: 16px;
  }
`;

const SwiperCoupon = styled(Swiper)``;

const SwiperBanner = styled(Swiper)`
  @media (max-width: ${md}) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
`;

const BannerFrame = styled.div`
  ${mixinBanner};
  display: contents;
`;

const Banner = styled.img`
  ${mixinBanner};
  display: block;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const SwiperCategory = styled(Link)`
  ${mixinCategory};
  display: block;
  background-color: #fff;
  padding: 4px 9px;
  text-align: center;
`;

const SwiperCategoryImg = styled.img`
  width: 61px;
  height: 57px;
`;

const SwiperCategoryLabel = styled.div`
  color: #3b3516;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const MobileBannerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0 16px;
  overflow-x: scroll;
  padding-right: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${Banner} {
    flex: 0 0 235px;
  }
`;

const SLIDES_PER_VIEW_SUB_BANNER = 2.5;

const SwiperListSection = ({ homePageOne, homePageTwo, couponTicketList }) => {
  const categoryList = useSelector((state) => getters.getCategoryList(state));
  const [frequentlyPurchasedList, setFrequentlyPurchasedList] = useState();
  const [recommendProductList, setRecommendProductList] = useState();
  const [couponBannerList, setCouponBannerList] = useState();

  useEffect(() => {
    //常購商品及其他人也逛過因為格式跟popCategoriesList不同所以轉格式
    const frequentlyParam = {
      name: homePageOne?.userInfo.frequentlyPurchasedList.name,
      productList: homePageOne?.userInfo.frequentlyPurchasedList.rows,
    };
    const recommendParam = {
      name: '其他人也逛過',
      productList: homePageOne?.userInfo.recommendProductList,
    };
    setFrequentlyPurchasedList(frequentlyParam);
    setRecommendProductList(recommendParam);
    setCouponBannerList(homePageOne?.couponPomotionInfo.rows);
  }, [homePageOne]);

  return (
    <Container>
      {/* 商品分類（Mobile） */}
      {categoryList !== undefined ? (
        <SwiperBlock className="category">
          <SwiperBlockTitleBar>
            <SwiperBlockTitle>商品分類</SwiperBlockTitle>
            <SwiperBlockCheckMore to="/category/all">
              看全部
            </SwiperBlockCheckMore>
          </SwiperBlockTitleBar>
          <Swiper
            modules={[Grid, Navigation]}
            slidesPerView={3.2}
            spaceBetween={8}
            navigation
            grid={{ rows: 2 }}
            style={{ height: '200px' }}
          >
            {categoryList?.map((item, idx) => {
              const sub = item.sub[0];
              return (
                <SwiperSlide key={idx}>
                  <SwiperCategory to={`/category?cid=${sub.id}`}>
                    <SwiperCategoryImg src={item.image} />
                    <SwiperCategoryLabel>{item.name}</SwiperCategoryLabel>
                  </SwiperCategory>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SwiperBlock>
      ) : (
        <Skeleton
          containerClassName="skeleton-category-wrapper"
          className="skeleton-category"
          count={6}
          inline
        />
      )}

      {/* 其他人也逛過 */}
      {recommendProductList?.productList?.length > 0 && (
        <SwiperBlock>
          <SwiperBlockContent item={recommendProductList} />
        </SwiperBlock>
      )}
      {couponTicketList.length > 0 && (
        <SwiperBlock>
          <SwiperBlockTitleBar>
            <SwiperBlockTitle>折價券</SwiperBlockTitle>
            <SwiperBlockCheckMore>
              <Link to="/coupon">看更多</Link>
            </SwiperBlockCheckMore>
          </SwiperBlockTitleBar>

          <SwiperCoupon
            autoHeight
            slidesPerView={isMobile ? 1.3 : 2.5}
            slidesPerGroup={Math.floor(isMobile ? 1.3 : 2.5)}
            spaceBetween={isMobile ? 8 : 4}
          >
            {couponTicketList.map((coupon, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <Coupon data={coupon} />
                </SwiperSlide>
              );
            })}
          </SwiperCoupon>
        </SwiperBlock>
      )}

      {/* 常購商品 */}
      {frequentlyPurchasedList?.productList?.length > 0 && (
        <SwiperBlock>
          <SwiperBlockContent item={frequentlyPurchasedList} />
        </SwiperBlock>
      )}

      {/* ------- 小 Banner ------- */}
      {couponBannerList?.length > 0 && !isMobile && (
        <SwiperBlock>
          <SwiperBanner
            autoHeight
            slidesPerView={SLIDES_PER_VIEW_SUB_BANNER}
            slidesPerGroup={Math.floor(SLIDES_PER_VIEW_SUB_BANNER)}
            spaceBetween={16}
            navigation
            modules={[Navigation]}
          >
            {couponBannerList?.map((item, idx) => {
              return (
                <SwiperSlide
                  key={idx}
                  onClick={() => {
                    // GTAG: 點擊廣告輪播Banner(下)
                    setSelectPromotion(idx, item);
                  }}
                >
                  <Banner
                    onClick={() => {
                      item.web_redirect_url !== '' && item.redirectType === '2'
                        ? typeof window !== 'undefined'
                          ? window.open(item.web_redirect_url)
                          : ''
                        : navigate(urlFilter(item.web_redirect_url), {
                            replace: false,
                          });
                    }}
                    src={item.image}
                  />
                </SwiperSlide>
              );
            })}
          </SwiperBanner>
        </SwiperBlock>
      )}
      {couponBannerList?.length > 0 && isMobile && (
        <MobileBannerWrapper>
          {couponBannerList?.map((item, idx) => {
            return (
              <BannerFrame
                onClick={() => {
                  // GTAG: 點擊廣告輪播Banner(下)
                  setSelectPromotion(idx, item);
                }}
              >
                <Banner
                  key={idx}
                  onClick={() => {
                    item.web_redirect_url !== '' && item.redirectType === '2'
                      ? typeof window !== 'undefined'
                        ? window.open(item.web_redirect_url)
                        : ''
                      : navigate(urlFilter(item.web_redirect_url), {
                          replace: false,
                        });
                  }}
                  src={item.image}
                />
              </BannerFrame>
            );
          })}
        </MobileBannerWrapper>
      )}
      {!(couponBannerList?.length > 0) && (
        <Skeleton
          containerClassName="skeleton-banner-wrapper"
          className="skeleton-banner"
          count={isMobile ? 1.5 : 2.5}
          inline
        />
      )}
      {/* ------- 小 Banner ------- */}

      {homePageTwo?.popCategoriesList !== undefined
        ? homePageTwo?.popCategoriesList?.rows.map((item, idx) => {
            return (
              <SwiperBlock key={idx}>
                <SwiperBlockContent item={item} idx={idx} />
              </SwiperBlock>
            );
          })
        : Array(4)
            .fill(null)
            .map((_, idx) => {
              return (
                <Skeleton
                  key={idx}
                  containerClassName="skeleton-pop-category-wrapper"
                  className="skeleton-pop-category"
                  count={isMobile ? 2.5 : 4.1}
                  inline
                />
              );
            })}
    </Container>
  );
};

export default SwiperListSection;
