import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { md } from 'utils/breakpoints';
import { setProgressBar } from 'utils/_gtag.js';

import IconRadar from 'images/radar.svg';
import IconArrow from 'images/arrow-1.svg';

const Container = styled.div`
  display: flex;
  gap: 0 8px;
  width: 1080px;
  margin: 16px auto 0;

  @media (max-width: ${md}) {
    margin-bottom: 0px;
    padding: 0 15px;
    flex-direction: column;
    gap: 8px 0;
    width: 100%;
  }

  ${(props) =>
    props.expand &&
    css`
      ${ExpandBtn} {
        width: 56px;

        ${StyledIconRadar} {
          display: none;
        }

        ${ExpandBtnText} {
          display: none;
        }

        ${StyledIconArrow} {
          transform: rotate(180deg);

          @media (max-width: ${md}) {
            transform: rotate(-90deg);
          }
        }
      }

      ${NotificationList} {
        display: flex;
      }
    `}
`;

const NotificationList = styled.div`
  flex: 1;
  display: none;
  flex-direction: column;
  gap: 8px 0;
  background-color: #ffffff;
  border: 2px solid #31aab3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px;
  max-height: 80vh;
  overflow-y: scroll;
`;

const Notification = styled.div`
  background-color: #31aab3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;

  @media (max-width: ${md}) {
    width: 100%;
  }

  &:hover {
    background-color: #54c7cf;
  }
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;

const ContentBlock = styled.div`
  &:nth-child(2) {
    @media (max-width: ${md}) {
      display: flex;
      flex-direction: column;
    }
  }

  > span {
    font-size: 12px;
    margin-left: 10px;

    @media (max-width: ${md}) {
      margin-left: 0px;
    }
  }
`;

const StyledIconRadar = styled.img``;

const ExpandBtnText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;

const StyledIconArrow = styled.img`
  margin-left: auto;

  @media (max-width: ${md}) {
    transform: rotate(90deg);
  }
`;

const ExpandBtn = styled.div`
  width: 192px;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 0 8px;
  background-color: #31aab3;
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  margin-top: 8px;

  @media (max-width: ${md}) {
    margin-top: 0;
  }
`;

const OrderNotification = (props) => {
  const { shippingOrderList } = props;

  const [deliveryTime, setDeliveryTime] = useState(
    shippingOrderList.map((item) => {
      return {
        shipping_model_id: item.shipping_model_id,
        delivery_datetime: item.delivery_datetime,
        estimated_delivery_time: item.estimated_delivery_time,
      };
    })
  );
  const [expand, setExpand] = useState(false);

  const goOrderDetailPage = (transNo) => {
    // GTAG: 12. 運送進度條
    setProgressBar();
    navigate(`/order/${transNo}?source=list`);
  };

  useEffect(() => {
    deliveryTime.forEach((item, idx) => {
      if (item.estimated_delivery_time !== 0) {
        const date = new Date(item.estimated_delivery_time * 1000);
        const nowTime = new Date();
        const timeDifference = date.getTime() - nowTime.getTime();

        const delayedSetDeliveryTime = _.debounce(() => {
          setDeliveryTime((prevDeliveryTime) => {
            const updatedDeliveryTime = [...prevDeliveryTime];
            updatedDeliveryTime[idx] = {
              ...updatedDeliveryTime[idx],
              delivery_datetime: '--',
            };
            return updatedDeliveryTime;
          });
        }, timeDifference);

        delayedSetDeliveryTime();
      }
    });
  }, []);

  return (
    <Container expand={expand}>
      <ExpandBtn onClick={() => setExpand((state) => !state)}>
        <StyledIconRadar src={IconRadar} />
        <ExpandBtnText>目前訂單狀態</ExpandBtnText>
        <StyledIconArrow src={IconArrow} />
      </ExpandBtn>
      <NotificationList>
        {shippingOrderList?.map((item, idx) => {
          return (
            <Notification
              key={idx}
              onClick={() => {
                goOrderDetailPage(item.sfcc_transaction_no);
              }}
            >
              <Content>
                <ContentBlock>
                  {item.shipping_model_id === '1'
                    ? ' 預估抵達時間：'
                    : ' 預估完成時間：'}
                  {deliveryTime[idx].delivery_datetime}
                </ContentBlock>
                <ContentBlock>
                  {item.ship_status}
                  <span>#訂單號：{item.order_no}</span>
                </ContentBlock>
              </Content>
              <StyledIconArrow src={IconArrow} />
            </Notification>
          );
        })}
      </NotificationList>
    </Container>
  );
};

export default OrderNotification;
