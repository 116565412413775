import React from 'react';
import { Helmet } from 'react-helmet';
import { SEO_DOMAIN } from 'utils/constants/seo.js';

const LdHome = ({ serverData }) => {
  var schemaJSONLDFrequentlyPurchasedListItemList = [];
  serverData?.home1.data.userInfo.frequentlyPurchasedList?.rows?.forEach(
    (product, idx) => {
      schemaJSONLDFrequentlyPurchasedListItemList.push({
        '@type': 'Product',
        position: `${idx + 1}`,
        brand: {
          '@type': 'Brand',
          name: `${product?.brand}`,
        },
        image: [`${product?.images ?? product?.image}`],
        name: `${product?.itemName}`,
        description: `${product?.itemSpec}`,
        url: `${SEO_DOMAIN}/product?pid=${product?.productNumber}`,
        offers: {
          '@type': 'Offer',
          price: `${
            product?.salePrice !== product?.price
              ? product?.salePrice
              : product?.price
          }`,
          priceCurrency: 'TWD',
          availability: 'http://schema.org/InStock',
        },
      });
    }
  );
  var schemaJSONLDFrequentlyPurchasedList = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDFrequentlyPurchasedListItemList,
  };

  // recommendProductList 推薦商品
  var schemaJSONLDRecommendProductListItemList = [];
  serverData?.home1?.data?.userInfo?.recommendProductList?.forEach(
    (product, idx) => {
      schemaJSONLDRecommendProductListItemList.push({
        '@type': 'Product',
        position: `${idx + 1}`,
        brand: {
          '@type': 'Brand',
          name: `${product?.brand}`,
        },
        image: [`${product?.images ?? product?.image}`],
        name: `${product?.itemName}`,
        description: `${product?.itemSpec}`,
        url: `${SEO_DOMAIN}/product?pid=${product?.productNumber}`,
        offers: {
          '@type': 'Offer',
          price: `${
            product?.salePrice !== product?.price
              ? product?.salePrice
              : product?.price
          }`,
          priceCurrency: 'TWD',
          availability: 'http://schema.org/InStock',
        },
      });
    }
  );
  var schemaJSONLDRecommendProductList = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDRecommendProductListItemList,
  };

  // PopCategoriesList 分類商品
  var schemaJSONLDPopCategoriesListItemList = [];
  serverData?.home2?.data?.popCategoriesList?.rows?.forEach((cate, idx) => {
    cate.productList.forEach((product, idx) => {
      schemaJSONLDPopCategoriesListItemList.push({
        '@type': 'Product',
        position: `${idx + 1}`,
        image: [`${product?.images ?? product?.image}`],
        name: `${product?.itemName}`,
        description: `${product?.itemSpec}`,
        url: `${SEO_DOMAIN}/product?pid=${product?.productNumber}`,
        offers: {
          '@type': 'Offer',
          price: `${
            product?.salePrice !== product?.price
              ? product?.salePrice
              : product?.price
          }`,
          priceCurrency: 'TWD',
          availability: 'http://schema.org/InStock',
        },
      });
    });
  });
  var schemaJSONLDPopCategoriesList = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDPopCategoriesListItemList,
  };

  // category
  var schemaJSONLDCateMenuItemList = [];
  serverData?.cateMenu?.data?.rows?.forEach((cate, idx) => {
    cate?.sub?.forEach((subItem, idx) => {
      schemaJSONLDCateMenuItemList.push({
        '@type': 'ListItem',
        position: `${idx + 1}`,
        name: `${subItem?.name}`,
        url: `${SEO_DOMAIN}/category?cid=${subItem?.id}`,
      });
    });
  });
  var schemaJSONLDCateMenu = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDCateMenuItemList,
  };

  // pomotion
  var schemaJSONLDPomotionItemList = [];
  serverData?.home1?.data?.couponPomotionInfo?.rows?.forEach(
    (pomotion, idx) => {
      schemaJSONLDPomotionItemList.push({
        '@type': 'ListItem',
        position: `${idx + 1}`,
        name: `${pomotion?.imageDesc}`,
        url: `${pomotion?.url}`,
      });
    }
  );
  var schemaJSONLDPomotion = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDPomotionItemList,
  };

  // banner
  var schemaJSONLDBannerItemList = [];
  serverData?.home1?.data?.bannerInfo?.rows?.forEach((banner, idx) => {
    schemaJSONLDBannerItemList.push({
      '@type': 'ListItem',
      position: `${idx + 1}`,
      name: `${banner?.name}`,
      url: `${banner?.url}`,
    });
  });
  var schemaJSONLDBanner = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDBannerItemList,
  };

  var schemaJSONLDGraphArray = [];
  schemaJSONLDGraphArray.push(schemaJSONLDBanner);
  schemaJSONLDGraphArray.push(schemaJSONLDPomotion);
  schemaJSONLDGraphArray.push(schemaJSONLDCateMenu);
  schemaJSONLDGraphArray.push(schemaJSONLDPopCategoriesList);
  schemaJSONLDGraphArray.push(schemaJSONLDFrequentlyPurchasedList);
  schemaJSONLDGraphArray.push(schemaJSONLDRecommendProductList);

  var jsonldSchema = {
    '@context': 'https://schema.org/',
    '@graph': schemaJSONLDGraphArray,
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonldSchema)}
        </script>
      </Helmet>
    </>
  );
};

export default LdHome;
