import React, { useEffect, useState, useRef } from 'react';
import store, { actions, getters } from 'redux/store';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { getDispatchData, updateDispatchData } from 'utils/_dispatch.js';
import { isAuthenticated } from 'utils/_auth';
import { setHomeMenu, setProductMenu } from 'utils/_gtag.js';
import { PATH_CATEGORY } from 'utils/constants/paths.js';

import IconArrow from 'images/Vector-Black.svg';

const Container = styled.div`
  flex: 0 0 200px;
  height: ${(props) => props.height ?? 'auto'};
  overflow-y: scroll;
  border-radius: 8px;

  @media (max-width: ${md}) {
    display: none;
  }

  .skeleton-wrapper {
    display: flex;
    height: 1000px;
  }

  .skeleton {
    height: 100%;
  }
`;

const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
  padding: 8px 16px;
  cursor: pointer;
`;

const CategoryMenuWrapper = styled.div`
  background-color: #fff;
  padding: 12px;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isActive &&
    css`
      ${MainCategory} {
        > img {
          transform: rotate(180deg);
        }
      }

      ${SubCategoryList} {
        display: flex;
      }
    `}
`;

const MainCategory = styled(Btn)`
  padding: 8px 16px;

  > img {
    transform: rotate(90deg);
  }
`;

const SubCategoryList = styled.div`
  display: none;
  flex-direction: column;
`;

const SubCategory = styled(Link)`
  font-weight: 400;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#5fd2da' : 'transparent')};
  color: ${(props) => (props.isActive ? '#fff' : '#000')};

  &:hover {
    background-color: ${(props) => (props.isActive ? '#5fd2da' : '#eafeff')};
    color: ${(props) => (props.isActive ? '#fff' : '#000')};
  }
`;

const CategoryMenu = (props) => {
  const { height, subCategoryId, onRefresh, onFinish } = props;
  const api = useAPI();
  const menuRef = useRef();

  const categoryList = useSelector((state) => getters.getCategoryList(state));

  const [activeIdList, setActiveIdList] = useState([]);

  const collapseContent = (mainCategory) => {
    const categoryId = mainCategory.id;
    if (activeIdList.includes(categoryId)) {
      const newList = activeIdList.filter((id) => id !== categoryId);
      setActiveIdList(newList);
    } else {
      setActiveIdList((state) => [...state, categoryId]);
    }
  };

  const getCategories = (storeId) => {
    if (storeId === '') return;
    api
      .getCategories(storeId)
      .then((res) => {
        if (!res) {
          store.dispatch(actions.setOnModalAddress(true));
          updateDispatchData('storeId', '');
          return;
        }
        store.dispatch(actions.setCategoryList(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategoriesNoToken = (storeId) => {
    if (storeId === '') return;
    api
      .getCategoriesNoToken(storeId)
      .then((res) => {
        if (!res) {
          store.dispatch(actions.setOnModalAddress(true));
          updateDispatchData('storeId', '');
          return;
        }
        store.dispatch(actions.setCategoryList(res));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 把當前 active 的分類 scroll 到 menu 最上方
  const scrollMenu = () => {
    const subCategoryElement = document.querySelector(
      `[data-id="${subCategoryId}"]`
    );
    const wrapperElement = subCategoryElement.closest('.category-wrapper');
    const menuTop = menuRef.current.getBoundingClientRect().top;
    const menuScrollTop = menuRef.current.scrollTop;
    const wrapperTop = wrapperElement.getBoundingClientRect().top;
    menuRef.current.scrollTop = wrapperTop - menuTop + menuScrollTop;
  };

  useEffect(() => {
    const dispatchData = getDispatchData();
    const storeId = dispatchData?.storeId ?? '';
    if (isAuthenticated()) {
      getCategories(storeId);
    } else {
      if (storeId === '') return;
      getCategoriesNoToken(storeId);
    }
  }, []);

  // 從 url 進頁面時需要預設展開 active 的分類
  useEffect(() => {
    if (categoryList && subCategoryId) {
      const category = categoryList?.find((category) => {
        return category.sub.find(
          (subCategory) => subCategory.id === subCategoryId
        );
      });
      setActiveIdList([category?.id]);
      scrollMenu();
    }
  }, [categoryList, subCategoryId]);

  useEffect(() => {
    if (onRefresh) {
      const dispatchData = getDispatchData();
      const storeId = dispatchData?.storeId ?? '';
      getCategories(storeId);
      onFinish();
    }
  }, [onRefresh]);

  return (
    <Container ref={menuRef} height={height}>
      {categoryList !== undefined ? (
        <CategoryMenuWrapper>
          {categoryList?.map((category) => {
            return (
              <CategoryWrapper
                key={category.id}
                className="category-wrapper"
                isActive={activeIdList.includes(category.id)}
              >
                <MainCategory onClick={() => collapseContent(category)}>
                  {category.name}
                  <img src={IconArrow} />
                </MainCategory>
                <SubCategoryList>
                  {category.sub.map((subCategory) => {
                    return (
                      <SubCategory
                        data-id={subCategory.id}
                        key={subCategory.id}
                        to={`/category?cid=${subCategory.id}`}
                        isActive={subCategoryId === subCategory.id}
                        onClick={() => {
                          setActiveIdList(
                            activeIdList.filter((id) => id === category.id)
                          );
                          if (
                            window.location.pathname.includes(PATH_CATEGORY)
                          ) {
                            setProductMenu({
                              mainCate: category.name,
                              subCate: subCategory.name,
                            });
                          } else {
                            // 首頁點擊分類
                            // GTAG: 7. 左側的分類文字選單
                            setHomeMenu({
                              mainCate: category.name,
                              subCate: subCategory.name,
                            });
                          }
                        }}
                      >
                        {subCategory.name}
                      </SubCategory>
                    );
                  })}
                </SubCategoryList>
              </CategoryWrapper>
            );
          })}
        </CategoryMenuWrapper>
      ) : (
        <Skeleton
          containerClassName="skeleton-wrapper"
          className="skeleton"
          count={1}
          inline
        />
      )}
    </Container>
  );
};

export default CategoryMenu;
